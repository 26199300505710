import {
  Module,
  VuexModule,
  MutationAction,
  getModule,
  Action,
  Mutation
} from "vuex-module-decorators";
import store from "@/store";
import API from "@/store/API/DemandAndSupply";
import {
  Supply,
  Demand,
  SeedType,
  Preferences,
  Supplier,
  MarketStat,
  SupplierStat,
  SupplierInfo,
  SupplierInfoPaginated,
  SupplierDetail,
  NoticeList,
  ClusterPaginated,
  PaginatedSupply,
  PaginatedDemand,
  Distribution,
  PaginatedDistribution,
  PaginatedUnsoldViewDetail,
  AdminTotalSupplyQuantityAPI,
  AdminTotalDemandQuantityAPI
} from "@/store/models/DemandAndSupply";
import { SeedClass } from "@/store/models/user";
import { CropVarietyPaginated } from "@/store/models/cropVariety";

@Module({ name: "DemandSupplyStore", dynamic: true, store })
class CropVarietyModule extends VuexModule {
  public SeedTypeList: SeedType[] = [] as SeedType[];
  public SeedClassList: SeedClass[] = [] as SeedClass[];
  public Demands: Demand[] = [] as Demand[];
  public PaginatedDemand: PaginatedDemand = {} as PaginatedDemand;
  public DemandCluster: ClusterPaginated = {} as ClusterPaginated;
  public Supplies: Supply[] = [] as Supply[];
  public Supply: Supply = {} as Supply;
  public PaginatedSupply: PaginatedSupply = {} as PaginatedSupply;
  public SupplyCluster: ClusterPaginated = {} as ClusterPaginated;
  public Suppliers: Supplier[] = [] as Supplier[];
  public Preferences: Preferences[] = [] as Preferences[];
  public MarketStat: MarketStat = {} as MarketStat;
  public SupplierStat: SupplierStat = {} as SupplierStat;
  public SupplierInfo: SupplierInfoPaginated = {} as SupplierInfoPaginated;
  public SupplierDetail: SupplierDetail = {} as SupplierDetail;
  public Notices: NoticeList = {} as NoticeList;
  public UnsoldDetailsPaginated: PaginatedDistribution = {} as PaginatedDistribution;
  public UnsoldViewDetail: PaginatedUnsoldViewDetail = {} as PaginatedUnsoldViewDetail;
  public UnsoldDemandDetail: Distribution = {} as Distribution;
  public TotalSupplyQuantity: AdminTotalSupplyQuantityAPI = {} as AdminTotalSupplyQuantityAPI;
  public TotalDemandQuantity: AdminTotalDemandQuantityAPI = {} as AdminTotalDemandQuantityAPI;

  @MutationAction
  async getAllSeedType(params?: { lang?: string }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.lang) {
        query += "&lang=" + String(params.lang);
      }
    }
    let seedTypeList = await API.getAllSeedType(query);
    return { SeedTypeList: seedTypeList as SeedType };
  }

  @MutationAction
  async getAdminDemands(params: {
    page?: number;
    fiscalYearId?: number;
    cropId?: number;
    seedTypeId?: number;
    cropVarietyId?: number;
    status?: string;
    searchCompany?: string;
    searchCode?: string;
  }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.fiscalYearId) {
        query += "&fiscalyearid=" + String(params.fiscalYearId);
      }
      if (params.cropId) {
        query += "&croptypeid=" + String(params.cropId);
      }
      if (params.seedTypeId) {
        query += "&seedtypeid=" + String(params.seedTypeId);
      }
      if (params.cropVarietyId) {
        query += "&cropvarietyid=" + String(params.cropVarietyId);
      }
      if (params.status) {
        query += "&status=" + String(params.status);
      }
      if (params.searchCode) {
        query += "&code=" + String(params.searchCode);
      }
      if (params.searchCompany) {
        query += "&companyname=" + String(params.searchCompany);
      }
    }
    let demandList = await API.getAdminDemands(query);
    return { PaginatedDemand: demandList as PaginatedDemand };
  }

  @MutationAction
  async getAllDemands(): Promise<any> {
    let demandList = await API.getAllDemands();
    return { Demands: demandList as Demand[] };
  }

  @MutationAction
  async getAllDemandCluster(
    params: {
      page?: number;
      fiscalYearId?: number;
      searchCode?: string;
      type?: any;
    } = {
      page: 1
    }
  ) {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.fiscalYearId) {
        query += "&fiscalyearid=" + String(params.fiscalYearId);
      }
      if (params.type) {
        query += "&type=" + String(params.type);
      }
      if (params.searchCode) {
        query += "&code=" + String(params.searchCode);
      }
    }
    let demandList = await API.getAllDemandCluster(query);
    return { DemandCluster: demandList as ClusterPaginated };
  }

  @MutationAction
  async getDemandByClusterId(clusterId: number): Promise<any> {
    let demandList = await API.getDemandSupplyByClusterId(clusterId);
    return { Demands: demandList as Demand[] };
  }

  @Action
  async postDemand(demand: Demand): Promise<any> {
    return await API.postDemand(demand);
    // return { SeedTypeList: seedTypeList as SeedType };
  }

  @Action
  async deleteDemand(id: number): Promise<any> {
    return await API.deleteDemand(id);
  }

  @Action
  async confirmDemands(ids: number[]) {
    await API.confirmDemands(ids);
  }

  @MutationAction
  async getAllSupplies(): Promise<any> {
    let supplyList = await API.getAllSupplies();
    return { Supplies: supplyList as Supply[] };
  }

  @MutationAction
  async getAdminSupplies(params: {
    page?: number;
    fiscalYearId?: number;
    cropId?: number;
    seedTypeId?: number;
    cropVarietyId?: number;
    districtId?: number;
    status?: string;
    searchCompany?: string;
    searchCode?: string;
  }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.fiscalYearId) {
        query += "&fiscalyearid=" + String(params.fiscalYearId);
      }
      if (params.cropId) {
        query += "&croptypeid=" + String(params.cropId);
      }
      if (params.seedTypeId) {
        query += "&seedtypeid=" + String(params.seedTypeId);
      }
      if (params.cropVarietyId) {
        query += "&cropvarietyid=" + String(params.cropVarietyId);
      }
      if (params.districtId) {
        query += "&districtid=" + String(params.districtId);
      }
      if (params.status) {
        query += "&status=" + String(params.status);
      }
      if (params.searchCode) {
        query += "&code=" + String(params.searchCode);
      }
      if (params.searchCompany) {
        query += "&companyname=" + String(params.searchCompany);
      }
    }
    let supplyList = await API.getAdminSupplies(query);
    return { PaginatedSupply: supplyList as PaginatedSupply };
  }

  @MutationAction
  async getAdminSuppliesTotalQuantity(params: {
    page?: number;
    fiscalYearId?: number;
    cropId?: number;
    seedTypeId?: number;
    cropVarietyId?: number;
    districtId?: number;
    status?: string;
    searchCompany?: string;
    searchCode?: string;
  }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.fiscalYearId) {
        query += "&fiscalyearid=" + String(params.fiscalYearId);
      }
      if (params.cropId) {
        query += "&croptypeid=" + String(params.cropId);
      }
      if (params.seedTypeId) {
        query += "&seedtypeid=" + String(params.seedTypeId);
      }
      if (params.cropVarietyId) {
        query += "&cropvarietyid=" + String(params.cropVarietyId);
      }
      if (params.districtId) {
        query += "&districtid=" + String(params.districtId);
      }
      if (params.status) {
        query += "&status=" + String(params.status);
      }
      if (params.searchCode) {
        query += "&code=" + String(params.searchCode);
      }
      if (params.searchCompany) {
        query += "&companyname=" + String(params.searchCompany);
      }
    }
    let TotalSupplyQuantity = await API.getAdminSuppliesTotalQuantity(query);
    return { TotalSupplyQuantity: TotalSupplyQuantity as AdminTotalSupplyQuantityAPI };
  }

  @MutationAction
  async getAdminDemandsTotalQuantity(params: {
    page?: number;
    fiscalYearId?: number;
    cropId?: number;
    seedTypeId?: number;
    cropVarietyId?: number;
    status?: string;
    searchCompany?: string;
    searchCode?: string;
  }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.fiscalYearId) {
        query += "&fiscalyearid=" + String(params.fiscalYearId);
      }
      if (params.cropId) {
        query += "&croptypeid=" + String(params.cropId);
      }
      if (params.seedTypeId) {
        query += "&seedtypeid=" + String(params.seedTypeId);
      }
      if (params.cropVarietyId) {
        query += "&cropvarietyid=" + String(params.cropVarietyId);
      }
      if (params.status) {
        query += "&status=" + String(params.status);
      }
      if (params.searchCode) {
        query += "&code=" + String(params.searchCode);
      }
      if (params.searchCompany) {
        query += "&companyname=" + String(params.searchCompany);
      }
    }
    let TotalDemandQuantity = await API.getAdminDemandsTotalQuantity(query);
    return { TotalDemandQuantity: TotalDemandQuantity as AdminTotalDemandQuantityAPI };
  }

  @MutationAction
  async getAllSupplyCluster(
    params: {
      page?: number;
      fiscalYearId?: number;
      searchCode?: string;
      type?: any;
    } = {
      page: 1
    }
  ) {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.fiscalYearId) {
        query += "&fiscalyearid=" + String(params.fiscalYearId);
      }
      if (params.type) {
        query += "&type=" + String(params.type);
      }
      if (params.searchCode) {
        query += "&code=" + String(params.searchCode);
      }
    }
    let supplyList = await API.getAllSupplyCluster(query);
    return { SupplyCluster: supplyList as ClusterPaginated };
  }

  @MutationAction
  async getSupplyByClusterId(clusterId: number): Promise<any> {
    let supplyList = await API.getDemandSupplyByClusterId(clusterId);
    return { Supplies: supplyList as Supply[] };
  }

  @MutationAction
  async getSupplyById(id: number): Promise<any> {
    let supply = await API.getSupplyById(id);
    return { Supply: supply as Supply };
  }

  @Action
  async postSupply(supply: Supply): Promise<any> {
    return await API.postSupply(supply);
    // return { SeedTypeList: seedTypeList as SeedType };
  }

  @Action
  async updateAdminSupply(supply: any): Promise<any> {
    return await API.updateAdminSupply(supply.id, supply);
  }

  @Action
  async updateAdminDemand(Demand: any): Promise<any> {
    return await API.updateAdminDemand(Demand.id, Demand);
  }

  @Action
  async deleteSupply(id: number): Promise<any> {
    return await API.deleteSupply(id);
  }

  @Action
  async confirmSupplies(ids: number[]) {
    await API.confirmSupplies(ids);
  }

  @MutationAction
  async getPreferences(): Promise<any> {
    let preferences = await API.getPreferences();
    return { Preferences: preferences as Preferences[] };
  }

  @Action
  async postPreference(preferences: Preferences): Promise<any> {
    return await API.postPreference(preferences);
    // return { SeedTypeList: seedTypeList as SeedType };
  }

  @Action
  async deletePreference(id: number): Promise<any> {
    return await API.deletePreference(id);
  }

  @MutationAction
  async getSuppliers(params: { cropVarietyId: number; seedTypeId: number }): Promise<any> {
    let supplyList = await API.getSupplier(params.cropVarietyId, params.seedTypeId);
    return { Suppliers: supplyList as Supplier[] };
  }

  @MutationAction
  async getCurrentMarketStat(): Promise<any> {
    let marketStat = await API.getCurrentMarketStat();
    return { MarketStat: marketStat as MarketStat };
  }

  @MutationAction
  async getSupplierStat(params?: { lang?: string }): Promise<any> {
    let query = "";
    if (params) {
      if (params.lang != null && params.lang != undefined) {
        query += "?lang=" + params.lang;
      }
    }
    let supplierStat = await API.getSupplierStat(query);
    return { SupplierStat: supplierStat as SupplierStat };
  }

  @MutationAction
  async getSupplierInfo(params?: {
    page?: number;
    cropId?: number;
    seedTypeId?: number;
    cropVarietyId?: number;
    districtId?: number;
    supplierName?: string;
  }): Promise<any> {
    let query = "";
    if (params) {
      query = "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.cropId) {
        query += "&croptypeid=" + String(params.cropId);
      }
      if (params.seedTypeId) {
        query += "&seedtypeid=" + String(params.seedTypeId);
      }
      if (params.cropVarietyId) {
        query += "&cropvarietyid=" + String(params.cropVarietyId);
      }
      if (params.districtId) {
        query += "&districtid=" + String(params.districtId);
      }
      if (params.supplierName) {
        query += "&companyname=" + String(params.supplierName);
      }
    }
    let supplierInfo = await API.getSupplierInfo(query);
    return { SupplierInfo: supplierInfo as SupplierInfoPaginated };
  }

  @MutationAction
  async getSupplierBySlug(slug: string): Promise<any> {
    let supplierDetail = await API.getSupplierBySlug(slug);
    return { SupplierDetail: supplierDetail as SupplierDetail };
  }

  @MutationAction
  async getNotices(): Promise<any> {
    let noticeList = await API.getNotices();
    return { Notices: noticeList as NoticeList };
  }

  @Action
  async distributionsSold(distributions: Distribution[]): Promise<any> {
    return await API.distributionsSold(distributions);
  }

  @Action
  async getBalanceSheetReport(params: {
    fiscalYearId: number;
    cropTypeId: number;
    seedTypeId: number;
  }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.fiscalYearId) {
        query += "&fiscalyearid=" + String(params.fiscalYearId);
      }
      if (params.cropTypeId) {
        query += "&croptypeid=" + String(params.cropTypeId);
      }
      if (params.cropTypeId) {
        query += "&seedtypeid=" + String(params.seedTypeId);
      }
    }
    return await API.getBalanceSheetReport(query);
  }

  @Action
  async getUnallocatedReport(params: {
    fiscalYearId: number;
    cropTypeId: number;
    seedTypeId: number;
  }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.fiscalYearId) {
        query += "&fiscalyearid=" + String(params.fiscalYearId);
      }
      if (params.cropTypeId) {
        query += "&croptypeid=" + String(params.cropTypeId);
      }
      if (params.cropTypeId) {
        query += "&seedtypeid=" + String(params.seedTypeId);
      }
    }
    return await API.getUnallocatedReport(query);
  }

  @MutationAction
  async unsoldAllocationReport(params: {
    page?: number;
    fiscalyearid?: number;
    provinceid?: number;
    districtid?: number;
  }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.fiscalyearid) {
        query += "&fiscalyearid=" + String(params.fiscalyearid);
      }
      if (params.provinceid) {
        query += "&provinceid=" + String(params.provinceid);
      }
      if (params.districtid) {
        query += "&districtid=" + String(params.districtid);
      }
    }
    let unsoldDetails = await API.unsoldAllocationReport(query);
    console.log(unsoldDetails);
    return { UnsoldDetailsPaginated: unsoldDetails as PaginatedDistribution };
  }

  @MutationAction
  async unsoldAllocationReportDetail(params: {
    page?: number;
    demandcompanyid: any;
    fiscalyearid?: any;
  }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.demandcompanyid) {
        query += "&demandcompanyid=" + String(params.demandcompanyid);
      }
      if (params.fiscalyearid) {
        query += "&fiscalyearid=" + String(params.fiscalyearid);
      }
      let unsoldViewDetail = await API.unsoldAllocationReportDetail(query);
      return { UnsoldViewDetail: unsoldViewDetail as PaginatedUnsoldViewDetail };
    }
  }

  @MutationAction
  async unsoldAllocationDemandDetail(params: {
    demandcompanyid: any;
    fiscalyearid?: any;
  }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.demandcompanyid) {
        query += "&demandcompanyid=" + String(params.demandcompanyid);
      }
      if (params.fiscalyearid) {
        query += "&fiscalyearid=" + String(params.fiscalyearid);
      }
      let unsoldDemandDetail = await API.unsoldAllocationDemandDetail(query);
      return { UnsoldDemandDetail: unsoldDemandDetail as Distribution };
    }
  }
}

export default getModule(CropVarietyModule);
